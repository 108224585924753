@import '~assets/less/palette.less';

.Container {
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;

  .Header {
    flex: 0;
  }

  .Main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .Footer {
    flex: 0;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;