.fieldContainer {
  display: flex;

  .label {
    flex-basis: 100%;
    text-align: right;
    margin: auto;
  }

  .labelAdmin {
    white-space: nowrap;
    margin-left: 1em;
  }

  .labelDetail {
    flex-basis: 30%;
    text-align: right;
    margin-left: 1em;
  }

  .spacerDetail {
    padding: 0 8px;
  }

  .spacer {
    padding: 0 8px;
    margin: auto;
  }
  .value {
    flex-basis: 100%;
    font-weight: bold;
    margin: auto;
    word-break: break-all;
    // When value contains '\n', should be displayed normally as a linebreak
    white-space: pre-wrap;
  }
  .singleLineValue {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .multiLineValue {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
  }
  .textValue {
    word-break: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    font-size: 0.9em;
  }
  .missingValue {
    font-weight: normal;
    font-style: italic;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;