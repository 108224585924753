.player-container {
  width: auto;
  height: fit-content;
  position: relative;

  .video-placeholder {
    width: 100%;
    height: auto;
    z-index: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;