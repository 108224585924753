@import '~assets/less/palette.less';

.ProfileForm {
  border: 1px solid black;
  border-radius: 3px;
  width: 50%;
  margin: 1px auto;
  padding: 25px;
}

.title {
  font-weight: bold;
  font-size: 1rem;
  margin-left: 5%;
  padding-bottom: 1rem;
}

// Override antd button's border
.edit-profile-button.ant-btn {
  border: none;
  box-shadow: none;
}

.day {
  margin-top: 0.2rem;
  border: 1px solid @grey-3b;
  padding: 2em;
}

.delete-hour {
  float: left;
  border: none !important;
  box-shadow: none !important;
}

.hours-widget {
  position: relative;
}

.custom-left-border {
  border-right: 1px solid @grey-3b;
}

.day-label {
  font-weight: bold;
  font-size: 1.1rem;
}

.add-hour-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  cursor: pointer;
}
.add-hour-button:hover {
  text-decoration: underline;
}

.calling-hours-span {
  margin-left: 2em;
}

.navigation {
  margin: 1em;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;