// Override antd button's border
.edit-profile-button.ant-btn {
  border: none;
  box-shadow: none;
}

.profile-form-header {
  padding-bottom: 20px; // center the first form input
  width: 100%;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;