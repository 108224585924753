@import '~assets/less/palette.less';

.table-page-size-changer {
  min-width: 100px;
}

.table-page-size-changer > .ant-select-selector {
  // Sorry for the !important...
  border-radius: 2px !important;
  border-color: @blue-1 !important;
}

.table-page-size-changer > .ant-select-selector > .ant-select-selection-item {
  width: 100%;
  font-weight: 500;
  font-family: Arial;
}

.table-page-size-changer > .ant-select-arrow {
  color: @blue-1;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;