@import '~assets/less/palette.less';

.suiviContainer {
  width: 30%;
}

.statusStyle {
  border-radius: 8px !important;
  vertical-align: middle;
}

.label-col {
  text-align: right;
  white-space: nowrap;
}

.field-col {
  text-align: center;
  display: block;
  margin-left: 1.5em;
}

.immatriculation {
  font-weight: bold;
  font-size: medium;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;