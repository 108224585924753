@import '~assets/less/palette.less';
@import 'constants.less';

.SuiviFourriere {
  height: 49px;
  line-height: 49px;
  border-bottom: @border;
  background-color: #f2f2f2;
  text-align: center;

  span {
    font-weight: 500;
    font-size: 16px;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;