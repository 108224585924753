.UpdateFicheForm {
  .FormModalTitle {
    margin-bottom: 1.5em;
  }

  .ModalFooter {
    text-align: center;
    border: none;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;