.ConfirmationMessage {
  font-weight: bold;
  margin: -1em auto 1.5em;
  text-align: center;
}

.ant-form-item-label {
  white-space: normal !important;
}

.MessageVIN {
  font-weight: bold;
  margin: 0em auto 1.5em;
  text-align: center;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;