.modalValidity {
  .alertUsed {
    margin-bottom: 24px;
  }

  .modalActions {
    display: flex;
    justify-content: center;

    button {
      margin: 0 0.5em;
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;