.EventTypeLabel {
  .LinkContent {
    display: flex;

    .Date {
      white-space: nowrap;
    }

    .Divider {
      margin-left: 0.2em;
      margin-right: 0.2em;
    }

    .StatusContent {
      padding: 0.25em 0.5em;
      font-size: 0.8em;
      white-space: nowrap;
    }
  }
  .DetailsList {
    list-style: none;
    padding: 0;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;