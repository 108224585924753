.MainInfoBody {
  display: grid;
  grid-template-columns: minmax(20em, 1fr) 20px minmax(35em, 1fr);
  margin: 1em;

  .DetailInfo {
    width: fit-content;
    margin: auto 0;
  }

  .Divider {
    height: 7em;
    margin: auto;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
  }

  .fieldContainer {
    white-space: nowrap;
    margin-bottom: 0.25em;
  }
}


@media only screen and (max-width: 920px) {
  .MainInfoBody {
    grid-template-columns: 1fr;

    .Divider {
      display: none;
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;