.fieldSummaryContainer {
  display: flex;
  margin-bottom: 2em;

  .label {
    flex-basis: 80%;
    text-align: right;
    // align label with the first value line
    margin: 0 auto auto;
  }
  .spacer {
    padding: 0 8px;
    margin: 0 auto auto;
  }
  .value {
    flex-direction: vertical;
    flex-basis: 100%;
    font-weight: bold;
    margin: 0 auto auto;
  }
  .missingValue {
    font-weight: normal;
    font-style: italic;
  }
  .detail {
    font-weight: normal;
  }
  .interdictionCirculer {
    padding-top: 0.5em;
    font-weight: bold;
    font-style: italic;
  }
  p {
    margin: 0;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;