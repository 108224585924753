.modalDetails{
  .fieldContainer {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.modalDetailsUniteFO{
  .ant-modal-body{
    padding: 0;

    .modalContent{
      padding: 24px;
    }

    .detailRpsiStatus{
      padding-left: 7px;
      padding-right: 32px;
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;