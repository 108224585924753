// bring the form inputs closer
.coordonneesUniteFoForm .ant-form-item {
  margin-bottom: 0.7em;
}

.fields {
  .fieldContainer {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}


@primary-color: #355689;@process-color: #355689;@info-color: #355689;