.NotFoundPage {
  text-align: center;
  font-size: 1.5em;
  margin: 5em 2em auto;

  .NotFoundImg {
    max-width: 26em;
  }

  .button {
    margin: 0.4em;
  }
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;