@import '~assets/less/palette.less';

@default-padding: 1em;

.FAQ-container {
  width: 90%;
  max-width: 80rem;
  margin: 1.8rem auto 3rem;

  .FAQ {
    width: 70rem;
    max-width: 90%;
    margin: auto;
    padding: @default-padding;

    .FAQ-title {
      text-align: center;
    }

    .question {
      padding: @default-padding;

      .question-title {
        font-size: 1.1rem;
        font-weight: bold;
        color: @blue-1;
      }

      .question-answer {
        white-space: pre-line;
      }
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;