@import '~assets/less/palette.less';

.HeaderContainer {
  flex: 0;
  background-color: @white-1;

  .TopBarContainer {
    width: 100%;
    height: 70px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    background-color: @white-1;
    border-bottom: solid 1px @grey-1;

    // quick fix to have a pseudo-responsive banner
    overflow: hidden;

    .Left {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .Banner {
        height: 100%;
      }
    }

    .Right {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;

      .DownloadableFilesButton {
        margin: auto 1em;
      }
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;