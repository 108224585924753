@import 'assets/less/palette.less';

.TimelineContainer {
  padding-right: .25em;

  .EventsTimeline {
    .EventItem {
      padding-bottom: 0;

      .ant-timeline-item-tail {
        border-color: @black-1;
        border-width: 1px;
        z-index: 5;
        top: 10px;
        height: 100%;
        left: calc(3.5em - 1px);
      }

      .ant-timeline-item-head {
        padding: 0 1px;
        color: @black-1;
        z-index: 6;
        top: 14px;
        left: 3.5em;
        border-radius: 50%;
      }

      .ant-timeline-item-content {
        margin-left: 0;
        display: flex;
      }

      .ant-timeline-item-last > .ant-timeline-item-content {
        min-height: 0;
      }

      .DetailLink {
        padding: 10px 4.5em 10px 4.5em;
        cursor: pointer;
        flex: 1;
        color: @black-1;

        .Label {
          font-weight: bold;
        }

      &:hover {
        background: @grey-5;

          &::after,
          &::before {
            content: '';
            position: absolute;
            background: @white-1;
            width: 3em;
            height: 3em;
            transform: rotate(45deg);
          }

          &::before {
            right: -1.5em;
            top: -1.5em;
          }

          &::after {
            right: -1.5em;
            bottom: -1.5em;
          }
        }
      }

      &.IncidentItem {
        .ant-timeline-item-head {
          color: @red-1;
        }

        .DetailLink {
          color: @red-1;
        }
      }
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;