.inputContent {
  margin: 0 3px;
  &--label {
    margin-bottom: 10px;

    &::before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
  &__body {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    padding: 5px 0;
    min-height: 200px;

    &__options {
      text-align: center;
      &--option {
        cursor: pointer;
      }
      &--separator{
        margin: 0 5px;
      }
    }
    &__render {
      padding: 0 10px;
      white-space: pre-line;
    }
  }
}

@media screen and (min-width: 575px) {
  .inputContent {
    display: flex;
    justify-content: flex-end;
    align-content: flex-start;
    margin: 0 30px;
    &--label {
      margin-right: 5px;
      margin-top: 5px;
    }
    &__body {
      width: 342px;
    }
  }
}

.selected{
  font-weight: bold;
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;