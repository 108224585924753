
.FicheHeaderContainer {
  width: 90%;
  max-width: 80rem;
  margin: 1em auto 0;
  display: flex;
}

.LeftFicheAdmin,
.RightFicheAdmin {
  flex: 1;
}

.CenterFicheAdmin {
  margin: 0 auto;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;