.profile-form-container {
  border: 1px solid black;
  border-radius: 3px;
  width: 50%;
  margin: 1px auto;
  padding: 25px;
}

.profile-form-title {
  font-weight: bold;
  font-size: 1rem;
  margin-left: 5%;
  padding-bottom: 1rem;
}

// Override antd button's border
.edit-profile-button.ant-btn {
  border: none;
  box-shadow: none;
}

.profile-form-buttons {
  display: flow-root;

  .cancelButton {
    float: right;
    margin-right: 50px;
  }

  .submitButton {
    float: right;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;