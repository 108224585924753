.DragSortableTable tr,
.RowDragging {
    display: flex;
        td,
        th {
            flex: 1;

            &:first-child {
                text-align: left !important;
                padding-left: 2em !important;
            }

            &:last-child {
                text-align: right !important;
                padding-right: 2em !important;
            }
        }
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;