// We need to define antd classname as well to prevent antd style from overriding this style
@search-plaque-border-radius: 25px;

.rounded-search-bar {
  .ant-input-search-button {
    border-top-right-radius: @search-plaque-border-radius !important;
    border-bottom-right-radius: @search-plaque-border-radius !important;
  }

  .ant-input {
    border-top-left-radius: @search-plaque-border-radius;
    border-bottom-left-radius: @search-plaque-border-radius;
  }
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;