.ModalProlongation {
  .ant-modal-body {
    padding: 0;

    #ProlongationForm {
      padding: 24px 0;
      padding-bottom: 0;

      .DateFinMef {
        display: flex;
        justify-content: center;

        > span {
          flex: 1;

          &.ant-descriptions-item-label {
            justify-content: flex-end;
          }

          &.ant-descriptions-item-content {
            font-weight: bold;
          }
        }
      }
    }
  }

  .ModalFooter {
    text-align: right;
    padding: 10px 16px;
    border-top: 1px solid #f0f0f0;

    .ValidateFormButton {
      margin-left: 8px;
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;