.UploadCard {
  position: relative;

  .ResultIcon {
    position: absolute;
    top: 0;
    right: .5rem;
    padding: 0;

    * {
      height: 1.25rem;
      width: 1.25rem;
      padding: 0;
      margin: 0;
    }
  }
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;