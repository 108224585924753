.WaitingForReferentiels {
  display: flex;
  margin-bottom: 24px;

  .label {
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;

    &::after {
      position: relative;
      content: ':';
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }

  .input {
    min-height: 32px;
    display: flex;
    align-items: center;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;