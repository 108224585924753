.noActionsMessage {
  max-width: 450px;
}

.modalMainLevee{
  .ant-modal-body{
    padding: 0;

    .modalContent{
      padding: 24px;
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;