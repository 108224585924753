@import '~assets/less/palette.less';

.Image {
  height: 100%;
  margin-right: 20px;
  margin-left: 10px;
}

.Title {
  margin-top: 8px;
  text-align: center;
}

.TitleText {
  font-size: 20pt;
  font-family: Georgia;
  color: @grey-3;
  font-weight: normal;
}

.TitleStrongText {
  font-weight: bolder;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;