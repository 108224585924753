.right {
  float: right;
}

.ClassementForm {
  margin: auto;

  .navigation {
    display: flow-root;

    .submitButton {
      .right();
    }

    .cancelButton {
      .right();
      margin-right: 50px;
    }
  }
}

.UpdateInformationsTechniques {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;

  .UpdateAction {
    margin-right: 0.25em;
    margin-left: 0.25em;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;