.actionsRequisesTooltipTitle {
  padding-top: 1em;
  text-align: center;
}

.actionsRequisesIcon {
  font-size: 20px;
}

.actionsRequisesClickHere {
  text-decoration: underline;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;