@import 'assets/less/palette.less';

.comparaisonSivFdData {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 1em;
  grid-row-gap: 0;

  p {
    padding-bottom: 1.4em;
    margin: 0;
  }

  .nomChamps {
    grid-area: 2 / 1 / 7 / 3;
    text-align: right;
  }
  .donneesFd {
    grid-area: 1 / 3 / 7 / 4;

    .button {
      width: 100%;
    }
  }
  .donneesSiv {
    grid-area: 1 / 4 / 7 / 5;

    p {
      color: @blue-1;
    }
  }

  &.withoutFaussePlaque {
    grid-template-rows: repeat(5, 1fr);

    .nomChamps {
      grid-area: 2 / 1 / 6 / 3;
    }
    .donneesFd {
      grid-area: 1 / 3 / 6 / 4;
    }
    .donneesSiv {
      grid-area: 1 / 4 / 6 / 5;
    }
  }
}

.encart {
  border: 2px solid #355361;
  border-radius: 8px;
  text-align: center;
  padding: 0.75em 1.4em;

  p {
    font-weight: 600;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  column-gap: 1em;
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;