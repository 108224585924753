.UpdateDonneesVehiculeActions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;

  .UpdateAction {
    margin-right: 0.25em;
    margin-left: 0.25em;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;