.modalValidity {
  .alertInvalid {
    margin-bottom: 24px;

    &--txt {
      text-align: center;
      margin: 0;
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;