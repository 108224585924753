.Creation {
  .FicheDescriptive {
    width: 100%;
    max-width: 900px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;