.filters-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.filters-container > * {
  margin-right: 10px;
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;