.FicheContainer {
  display: flex;
  width: 100%;
  padding: 8px 8px;

  .node {
    flex-basis: 100%;
  }

  .separator {
    height: auto;
    width: 5px;
    margin: 15px 8px;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;