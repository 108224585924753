.dashboard-header-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.createFaqDomainButton {
  margin-right: 4em;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;