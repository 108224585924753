@import 'assets/less/palette.less';

.controlerDonnesVehiculeFormBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;

  .btnWrapper {
    margin: 0 0.25rem;
  }
}

.helpText {
  color: @blue-1;
  font-style: italic;
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;