@import '~assets/less/palette.less';

.DetailsContainer {
  &.incident {
    background: #ff000017;

    h3 {
      color: @red-1;
    }
  }

  padding-bottom: 2em;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;