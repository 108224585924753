/*
  when we just consult forms, data are bold
*/
.ConsultationValue {
  font-weight: bold;

  .EmptyValue {
    font-weight: normal;
  }
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;