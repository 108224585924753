.ActionsContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .ActionsButton {
    margin: 0.25em;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;