.FluxStatus {
  width: 2rem;
  height: 2rem;
  padding: 0 !important;
  margin: auto;

  * {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0;
    margin: 0;
  }
}

.dashboardVenteDossiers {
  margin: 1em;
}


@primary-color: #355689;@process-color: #355689;@info-color: #355689;