.dashboard-header-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-header-actions {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.dashboard-header-actions > * {
  margin-left: 10px;
}

.dashboard-number-vehicules {
  margin-top: 10px;
  margin-left: 5px;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;