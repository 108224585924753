@import '~assets/less/palette.less';

.DownloaderContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid @grey-4;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  padding: 0.2rem;
  font-size: 3rem;

  &:hover {
    background: @grey-5;
  }

  .DownloadIcon {
    color: @blue-1-hover;
  }

  .DownloadTitle {
    font-size: 1rem;
    margin: 0.35rem 0 1.03rem;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;