@import '~assets/less/palette.less';

.column-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.sort-button {
  margin-left: 10px;
  color: @black-1;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;