@import '~assets/less/palette.less';

.MainInfoHeader {
  height: fit-content;
  width: 100%;
  border-bottom: @fiche-dossier-admin-border;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: @white-1;

  .Immatriculation {
    font-weight: bold;
    margin: auto 0.5em auto 1em;
    font-size: @font-size-3;

    &.SansPlaque {
      font-weight: normal;
      font-style: italic;
    }
  }

  .TextBox {
    font-weight: 500;
    font-size: 1em;
    margin: auto 0 auto 1em;
    padding: 0.25em 1em;
    border-radius: 4px;
    border-width: 3px;
    border-style: solid;
  }

  .Branche {
    color: #7f7fc8;
    border-color: #7f7fc8;
  }

  .InterdictionCirculer {
    color: #484d7a;
    border-color: #484d7a;
  }

  .vol {
    color: #fe6363;
    border-color: #fe6363;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;