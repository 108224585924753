.suivi-dossier-timeline {
  margin-top: 1.4em;
  padding-right: 0.7em;
}

.suivi-dossier-timeline-label {
  margin-right: 1.4em;
}

.suivi-dossier-timeline-value {
  margin-left: 1.4em;
  margin-bottom: 1.4em;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;