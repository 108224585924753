.dashboard-header-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-header-filter {
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;
  margin-bottom: 1em;
}

.dashboard-header-filter > * {
  margin-right: 10px;
}

.dashboard-header-domain {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 1em;
}

.createFaqButton {
  margin-right: 4em;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;