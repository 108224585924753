.page-size-changer {
  align-self: flex-end;
}

.pagination-without-total {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  flex-wrap: wrap;

  .page-changer-container {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .page-number {
    margin: 5px;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;