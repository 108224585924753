@import '~assets/less/palette.less';
@base-size: 10px;

footer.footer {
  .url-footer {
    flex: 1;
    background: @white-1;
    padding: 0 @base-size;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;

      li {
        a {
          white-space: nowrap;
          margin-right: 20px;
          margin-left: 20px;
          font-size: 85%;
        }

        .defaultLink {
          color: @grey-2;
        }

        .coloredLink {
          color: @blue-1;
          font-weight: bold;
        }
      }
    }
  }

  .mint-footer {
    background: @grey-4 !important;
    display: grid;
    grid-template-columns: 1fr repeat(3, auto) 1fr;
    align-items: center;
    color: @white-1;
    bottom: 0;
    width: 100%;
    padding: 0 @base-size;
    justify-content: center;
  }

  .mint-footer span {
    color: @grey-2;
    font-size: 120%;
  }

  .mint-footer img {
    max-height: @base-size * 6;
    padding: 8px;
    // center the mint logo
    grid-column-start: 2;
  }

  // align the version to the left
  .mint-footer .version {
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .versionText {
      color: @grey-3;
      font-size: 80%;
      text-align: end;
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;