.dashboardWarningsDossier {
  margin: 1em;

  .tableContainer {
    margin-top: 40px;
    padding: 0 20px;
    white-space: pre-line;
    text-align:center;
  }

  .dossierLink,
  .ant-typography-expand {
    text-decoration: underline;
  }

}



@primary-color: #355689;@process-color: #355689;@info-color: #355689;