@import '~assets/less/palette.less';

.ErrorAlert {
  display: flex;
  flex-direction: row;
  background-color: @red-1;
  color: white;
  border-radius: 4px;
  padding: 1em;
  margin: 0.5em;
  height: 50px;
  box-shadow: .1em .1em .25em 0 rgba(0, 0, 0, .25);

  .ErrorIcon {
    font-size: 150%;
    margin-right: 10px;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;