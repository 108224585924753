@import '~assets/less/palette.less';

.renseignerInformationsSiv {
  .content {
    margin-top: 5%;
    padding: 1em 3em;
    border: 1px solid @grey-3;
    border-radius: 3px;
    transition-duration: 0s;
    margin-left: 5px;
    margin-right: 5px;
  }

  /*
    Edit antd step style and title between steps to:
    - put grey-3 the bar between steps
    - harmonize title steps colors
  */
  .ant-steps-item-custom {
    &.ant-steps-item-process,
    &.ant-steps-item-wait {
      .ant-steps-item-title::after {
        background-color: @grey-3b;
      }
    }

    &.ant-steps-item-wait {
      .ant-steps-item-title,
      .ant-steps-icon {
        color: @inactive-ant-step-color;
      }
    }
  }
}


@primary-color: #355689;@process-color: #355689;@info-color: #355689;