@card-margin: 1.5rem;

.Tutorial-page {
  width: 90%;
  max-width: 80rem;
  margin: 1.8rem auto 3rem;
  justify-content: center;

  .Tutorial-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
    margin: 0 3rem;

    h1 {
      grid-column: ~"1/-1";
      width: 100%;
      padding: 2rem @card-margin 0;
    }

    .noAvailableContent, .card {
      margin: @card-margin;
    }

    .ant-card-meta-title {
      overflow: visible;
      white-space: normal;
    }
  }
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;