@import '~assets/less/palette.less';

.FicheAdminEvents {
  display: flex;
  padding: 0;
  align-items: stretch;
  resize: vertical;
  min-height: 30em;

  .Column {
    flex: 1;

    &.DetailedEvents {
      position: relative;

      .DetailedEventsWrapper {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        .DetailsContainer {
          padding-left: 3em;
        }
      }
    }

    h2 {
      font-size: @font-size-3;
      margin-bottom: 1em;
      margin-left: 2em;
      margin-top: 0.3em;
    }
  }

  .ColumnDivider {
    flex: 0;
    z-index: 1000;
    border-left: @fiche-dossier-admin-border;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;