.timeline {
  margin-top: 6px;

  .ant-timeline-item-content {
    position: relative;
    top: -7.001px;
    margin: 0 0 0 20px;
    word-break: break-word;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;