@import '~assets/less/palette.less';

.FicheAdmin {
  .MainInfos {
    background-color: @grey-5;
  }

  .Section {
    border: @fiche-dossier-admin-border;
    margin-bottom: 1em;
    border-radius: 4px;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;