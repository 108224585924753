.FileItem {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: 1em;

  .FileItemMainArea {
    flex-grow: 1;
    font-size: 1.4em;

    .FileItemTitle {
      margin-top: auto;
      margin-bottom: auto;
    }

    .FileItemDetail {
      font-size: .75em;
    }

    .FileItemDate {
      font-style: italic;
      font-size: .75em;
    }
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;