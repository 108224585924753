.return-button {
  margin-top: 20px;
  margin-bottom: 20px;
}

.header {
  padding: 1.8em 0em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .mainInfo {
    float: left;
  }

  .container {
    flex-direction: row;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;