.navigation {
  display: flow-root;

  .left {
    float: left;
  }

  .right {
    float: right;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;