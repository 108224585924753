@import '~assets/less/palette.less';

@default-padding: 1em;

.MentionsLegalesContainer {
  width: 90%;
  max-width: 80rem;
  margin: 1.8rem auto 3rem;
}

.MentionsLegales {
  width: 70rem;
  max-width: 90%;
  margin: auto;
  padding: @default-padding;
}

.MentionsLegalesTitle {
  text-align: center;
}

.MentionsLegalesSubTitle {
  text-align: center;
}

.MetionLegaleSection {
  padding: @default-padding;
}

.MetionLegaleSectionTitle {
  font-size: 1.1rem;
  font-weight: bold;
  color: @blue-1;
}

// Easy way to hide email from (simple) bots
.MetionLegaleContact {
  font-style: italic;
}

.MetionLegaleContact::before {
  font-style: italic;
  content: 'si-fourrieres';
}

.MetionLegaleContact::after {
  font-style: italic;
  content: 'interieur.gouv.fr';
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;