.label {
  text-align: right;
}

.label-info {
  padding-right: 1em;
  color: rgba(0, 0, 0, 0.85);
}
.value-info {
  font-weight: bold;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;