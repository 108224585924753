// edit button should not overlap with the cross
.edit-profile-button {
  margin-right: 36px;
}

.mainTitle {
  margin-left: 1em;
}

// disable border-top in the inner form footers
.coordonneesProprietaireForm .ModalFooter {
  border: none;
}
.coordonneesUniteFoForm .ModalFooter {
  border: none;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;