@import '~assets/less/palette.less';

.table-container {
  margin-top: 20px;
}

.ant-table-row:hover > .ant-table-cell > .immat-link {
  text-decoration: underline;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;