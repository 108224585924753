@import '~antd/dist/antd.less';
@import './assets/less/palette.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page_size {
  width: 100vw;
  height: 100vh;
}

.ModalFooter {
  text-align: right;
  padding: 1em;
  border-top: 1px solid #f0f0f0;

  .ValidateFormButton {
    margin-left: 0.5em;
  }
}

.ant-btn-primary {
  &:hover {
    background-color: @white-1;
    border-color: @blue-1;
    color: @blue-1;
  }
}

.ant-timeline-item-content {
  margin: 0 0 0 18px;
}

.ant-form-item:has(span.ant-checkbox) {
  .ant-col {
    label {
      height: fit-content;
    }
    .ant-form-item-control-input {
      min-height: 0;
    }
  }
}

.ant-form-item:has(div.ant-form-item-extra) {
  margin-bottom: 4px;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;